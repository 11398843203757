module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"dangerouslySetAllPagesToNoIndex":false,"dangerouslySetAllPagesToNoFollow":false,"description":"Ricky Richards Photography British Photo Journalist and Documentary photographer based in San Francisco USA","openGraph":{"type":"website","locale":"en_US","url":"https://rickyrichards.photography/","site_name":"Ricky Richards Photography","images":[{"url":"./src/images/RR_Favicon.png","alt":"Ricky Richards Icon"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Work Sans:300,500,600"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ricky Richards Photography","short_name":"RR Photography","start_url":"/","background_color":"#fff","theme_color":"#1a1b1d","display":"standalone","icon":"./src/images/RR_Favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b4304bf04b14990192b8306caa38bb3f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
